import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Grid, Typography, Button } from '@mui/material'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Trailer from './trailer'
import Marque from './marque'
import LightBorder from './lightBorder'
import PattyOne from './videos/pattyOne'
import PattyTwo from './videos/pattyTwo'
import PattyThree from './videos/pattyThree'
import Dinosaur from './videos/dinosaur'
import Snail from './videos/snail'

const VideosSection = ({videos, image, isMobileLandscape, videoThumbnails}) => {
    const theme = useTheme()
    const _image = getImage(image)
    const [ activeVideo, setActiveVideo ] = useState(undefined)
    const playlists = [
        {
            id:'animals',
            title:'The Animals Series',
            description:'Animals is blah blah blah',
            videos:{
                dinosaur:{
                    name:'dinosaur',
                    video:<Dinosaur/>,
                    thumbnail:getImage(videoThumbnails.dinosaur)
                    
                },
                snail:{
                    name:'snail',
                    video:<Snail/>,
                    thumbnail:getImage(videoThumbnails.snail)
                }
            }
        },
        {
            id:'patty',
            title:'The Patty Series',
            description:'Patty is blah blah blah',
            videos:{
                patty1:{
                    name:'patty1',
                    video:<PattyOne/>,
                    thumbnail:getImage(videoThumbnails.patty1)
                },
                patty2:{
                    name:'patty2',
                    video:<PattyTwo/>,
                    thumbnail:getImage(videoThumbnails.patty2)
                },
                patty3:{
                    name:'patty3',
                    video:<PattyThree/>,
                    thumbnail:getImage(videoThumbnails.patty3)
                }
            }
        },
    ]
    const VideosStack = styled(Grid)({
        position:'relative',
        overflowX:'scroll',
        overflowY:'hidden',
        ...theme.scrollbar.horizontal,
        backgroundColor:theme.palette.gradients.richBlack,
        marginBottom:'15px',
        padding:'10px 0',
        '& p':{
            fontWeight:600,
            color:theme.palette.gradients.raisinblack,
            textAlign:'center',
            marginTop:'15px',
            fontSize:'16px',
        },
    })
    const RelativeContainer = styled('div')({
        overflow:'hidden',
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        marginLeft:isMobileLandscape ? '100px' : '0',
        backgroundColor:'#010002',
        paddingBottom:'200px',
    })
    const TitleContainer = styled(Grid)({
        width:'100%',
        backgroundColor:theme.palette.gradients.richBlack,
        '& h5':{
            fontSize:'16px',
            fontFamily:'Rubik',
            color:theme.palette.gradients.lavenderBlush,
            fontWeight:400,
            marginLeft:'10px'
        },
        '& p':{
            fontSize:'16px',
            fontFamily:'Rubik',
            color:theme.palette.gradients.lavenderBlush,
            fontWeight:400,
            padding:'0 10px',
            [theme.breakpoints.up('md')]:{
                fontSize:'22px'
            }
        },
    })
    const Cinema = styled('div')({
        position:'relative',
        [theme.breakpoints.up('lg')]:({
            maxWidth:'50%',
            transform:'translate(0,-35px)'
        }),
    })
    const CinemaImage = styled(GatsbyImage)({
        height:'100%',
        width:'150%',
        transform:'translate(-17%,0)'
    })
    const VideoGroupTitle = styled(TitleContainer)({
        padding:"0 0 10px 0",
        '& svg':{
            marginBottom:'10px'
        },
        '& h5':{
            fontSize:'20px',
            color:theme.palette.gradients.dodgerBlue,
            textAlign:'center'
        }
    })
    const ThumbnailContainer = styled(Button, {
        shouldForwardProp:prop=>
            prop !== 'position'
    })(({position})=>({
        position:position || 'relative',
        width:'270px',
        margin:'0 5px',
        padding:'0',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        marginBottom:'25px',
        ...theme.button1,
        '& svg':{
            borderRadius:'10px'
        },
        [theme.breakpoints.up('md')]:{
            position:'relative',
            transform:'rotate(0deg) translate(0,0)',
            width:'348px',
            height:'208px',
            marginTop:'10px'
        }
    }))
    const Thumbnail = styled(GatsbyImage)({
        width:'240px',
        height:'135px',
        backgroundColor:theme.palette.gradients.lavenderBlush,
        [theme.breakpoints.up('md')]:({
            width:'348px',
            height:'208px',
        })
    })
    const ThumbnailBorder = styled('div')({
        ...theme.button1Inner,
        width:'100%',
        height:'100%',
    })
    const GridItem = styled(Grid)({
        display:'flex',
        flexDirection:'column',
        width:'375px',
        marginLeft:'10px'
    })
const ComingSoon = styled('div')({
    '& h6':{
        fontSize:'25px',
        fontFamily:'Holtwood One SC',
        color:theme.palette.gradients.frostbite,
        textAlign:'center',
        margin:'25px 0 10px 0'
    },
    [theme.breakpoints.up('md')]:({
        '& h6':{
            fontSize:'55px',
            fontFamily:'Holtwood One SC',
            color:theme.palette.gradients.frostbite,
            margin:'50px 0 10px 0'
        },
    })
})
const FeaturesDesc = styled('div')({
    width:'98%',
    textAlign:'center',
    '& p':{
        fontSize:'16px',
        fontFamily:'Rubik',
        color:theme.palette.gradients.lavenderBlush,
        margin:'0 15px 25px 15px'
    }
})
  return (
    <RelativeContainer>
        <Marque/>
        <Cinema>
        <CinemaImage image={_image} alt={'cinema image'} />
        {
            !activeVideo
            &&
            <>
                <TitleContainer>
                    <LightBorder/>
                    <Typography variant="body2" align="center">
                        The same technologies used to create our websites can be used to create cool, custom animated videos.  Need a tutorial or promotional video for your product or service?  Or maybe a social media video to promote your next sale?  Lake has your animated videos needs covered.
                    </Typography>
                    <LightBorder/>
                </TitleContainer>
            </>

        }
        {
            activeVideo 
            ?
            activeVideo
            :
            <Trailer/>
        }
        </Cinema>
        {
            !activeVideo
            &&
            <>
                <ComingSoon><Typography variant="h6">FEATURE PRESENTATIONS</Typography></ComingSoon>
                <FeaturesDesc><Typography variant="body2">Click the thumbnails for a sampling of our promotional animations.  These animations were built using web code and are displayed here as they were built.  A secondary process captures the animation and coverts it into a video file where audio can then be added.  Because web code was used, we can output to any size or resolution. Visit our facebook or youtube channel for videos created from these web animations.</Typography></FeaturesDesc>
            </>
        }
        
            {
                playlists.map((list)=>{
                        return(
                            <>
                                <LightBorder styles={
                                        {
                                            width:'200%',
                                            overflow:'hidden',
                                            [theme.breakpoints.up('sm')]:{
                                                left:0,
                                                right:0,
                                                width:'100%'
                                            }
                                        }
                                    }/>
                                <VideoGroupTitle variant="h5">
                                    <Typography variant="h5">{list.title}</Typography>
                                </VideoGroupTitle>
                                <LightBorder styles={
                                        {
                                            width:'200%',
                                            overflow:'hidden',
                                            [theme.breakpoints.up('sm')]:{
                                                left:0,
                                                right:0,
                                                width:'100%'
                                            }
                                        }
                                    }/>
                                <VideosStack container flexDirection="row" flexWrap='nowrap'>
                                    {
                                        Object.keys(list.videos).map((videoKey)=>(
                                            <GridItem key={list.videos[videoKey].id}>
                                                <ThumbnailContainer onClick={(e)=>{e.target.closest('#sectionContainer').scrollTo(0,0); setActiveVideo(list.videos[videoKey].video)}}>
                                                    <ThumbnailBorder>
                                                        <Thumbnail image={list.videos[videoKey].thumbnail}/>
                                                    </ThumbnailBorder>
                                                </ThumbnailContainer>
                                            </GridItem>
                                        ))
                                    }
                                </VideosStack>
                             </>
                        )
                }).flat()
            }

    </RelativeContainer>
  )
}

export default VideosSection